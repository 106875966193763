import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Paper,
  TextField,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import Traceability from "Traceability/Traceability";
import withStyles from "@material-ui/core/styles/withStyles";
import { StylesContext } from "../../App";
import Message from "Components/Message";
import MuiAlert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "0px solid white",
        backgroundColor: "rgba(0, 0, 0, 0.029)",
      },
    },
  },
})(TextField);

const EMPTY_MESSAGE = {
  open: false,
  message: "",
  status: "info",
};

const findBaseUnit = (unitType, units) => {
  return Object.values(units).find(unit => unit.unit_type === unitType && unit.is_base);
};

const convertToBase = (value, unit, units) => {
  const baseUnit = findBaseUnit(unit.unit_type, units);
  if (!baseUnit) {
    throw new Error(`Base unit for type ${unit.unit_type} not found.`);
  }
  const conversionRate = parseFloat(unit.ratio_to_base);
  return value * conversionRate;
};

const convertFromBase = (value, targetUnit, units) => {
  const baseUnit = findBaseUnit(targetUnit.unit_type, units);
  if (!baseUnit) {
    throw new Error(`Base unit for type ${targetUnit.unit_type} not found.`);
  }
  const conversionRate = parseFloat(targetUnit.ratio_to_base);
  return value / conversionRate;
};


const FulfillmentModal = ({
  open,
  handleClose,
  handleSubmit,
  rowData,
  units,
  finishInventory,
}) => {
  const classes = React.useContext(StylesContext);
  const [lotCodeData, setLotCodeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [pickedQuantities, setPickedQuantities] = useState([]);
  const [decimalPoints, setDecimalPoints] = useState(false);

  useEffect(() => {
    if (open) {
      fetchLotCodeData();
    }
  }, [open]);

  function mergeData(resData, rowData) {
    const mergedData = [...resData];
    let lotCodeCounters = {};

    rowData.lot_codes.forEach((rowItem) => {
      if (!lotCodeCounters[rowItem.code]) {
        lotCodeCounters[rowItem.code] = 0;
      }

      const matchingItems = mergedData.filter(item => item.lot_code == rowItem.code);
      const matchIndex = lotCodeCounters[rowItem.code];

      if (matchingItems[matchIndex]) {
        let matchingItem = matchingItems[matchIndex];

        if (matchingItem.unit_type == rowItem.unit_type) {
          matchingItem.unit_value = parseFloat(matchingItem.unit_value) + parseFloat(rowItem.unit_value);
        } else {
          const lotUnit = units[rowItem.unit_type];
          const targetUnit = units[matchingItem.unit_type];
          const baseValue = convertToBase(rowItem.unit_value, lotUnit, units);
          const convertedQuantity = convertFromBase(baseValue, targetUnit, units);

          matchingItem.unit_value = parseFloat(matchingItem.unit_value) + parseFloat(convertedQuantity);
        }
      }
      lotCodeCounters[rowItem.code]++;
    });
    return mergedData;
  }

  const fetchLotCodeData = () => {
    if (!!rowData.amount_needed) {
      const valueString = rowData.amount_needed.toString();
      const decimalIndex = valueString.indexOf('.');
      if (decimalIndex !== -1) {
        const wholePart = valueString.substring(0, decimalIndex);
        const decimalPart = valueString.substring(decimalIndex + 1, decimalIndex + 3);
        if (parseInt(wholePart, 10) > 0) {
          setDecimalPoints(2);
        } else if (parseInt(wholePart, 10) === 0 && parseInt(decimalPart, 10) !== 0) {
          setDecimalPoints(2);
        } else {
          setDecimalPoints(4);
        }
      } else {
        setDecimalPoints(2);
      }
    }
    // console.log("finishInventory >> ",finishInventory);
    let filteredInventoryData = finishInventory
      .filter(item => item.product.id === rowData.product.id)
      .flatMap(item =>
        item.internal_lot_codes.map(code => ({
          ...code,
          lot_code: code.internal_lot_code // Rename internal_lot_code to lot_code
        }))
      );
    console.log("rowData >> ",rowData)  
    console.log("filteredInventoryData >> ", filteredInventoryData)
    // const result = mergeData(filteredInventoryData, rowData);
    // console.log("result >> ", result)

    const updatedLotCodeData = filteredInventoryData.map((lot) => {
      const lotUnit = units[lot.unit_type];
      const targetUnit = units[rowData.unit_type];

      if (!lotUnit || !targetUnit) {
        throw new Error(`Unit not found for lot or target.`);
      }

      const baseValue = convertToBase(lot.unit_value, lotUnit, units);
      const convertedQuantity = convertFromBase(baseValue, targetUnit, units);

      return { ...lot, unit_value: convertedQuantity, unit_type: rowData.unit_type };
    });
    setLotCodeData(updatedLotCodeData);
  };

  useEffect(() => {
    if (lotCodeData && rowData.target) {
      calculateRemainingQuantities();
    }
  }, [lotCodeData, rowData.target]);

  const calculateRemainingQuantities = () => {
    let targetValue = parseFloat(rowData.target.replace(/[^\d.-]/g, ""));
    // targetValue = 100;
    let updatedPickedQuantities = [];
    for (let lot of lotCodeData) {
      let pickedQuantity = 0;
      if (targetValue > 0) {
        if (lot.unit_value <= targetValue) {
          pickedQuantity = lot.unit_value.toFixed(decimalPoints);
          targetValue -= lot.unit_value;
        } else {
          pickedQuantity = targetValue.toFixed(decimalPoints);
          targetValue = 0;
        }
      }
      updatedPickedQuantities.push(pickedQuantity);
    }
    setPickedQuantities(updatedPickedQuantities);
  };

  const handlePickedQuantityChange = (event, index) => {
    const { value } = event.target;

    // Allow clearing the input
    if (value === "") {
      setPickedQuantities((prevPickedQuantities) => {
        const updatedPickedQuantities = [...prevPickedQuantities];
        updatedPickedQuantities[index] = "";
        return updatedPickedQuantities;
      });
      return;
    }

    // Validate and parse the input value
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
      return; // Ignore invalid input
    }

    // Format the value to ensure it has up to 2 decimal places
    const formattedValue = parseFloat(parsedValue.toFixed(decimalPoints));

    const existingQuantity = lotCodeData[index].unit_value;

    if (formattedValue <= existingQuantity) {
      setPickedQuantities((prevPickedQuantities) => {
        const updatedPickedQuantities = [...prevPickedQuantities];
        updatedPickedQuantities[index] = formattedValue;
        return updatedPickedQuantities;
      });
      setErrorMessage("");
    }
    else {
      setErrorMessage("Picked quantity cannot exceed existing quantity.");
    }
  };

  const handleSubmission = () => {
    const totalAvailable = lotCodeData ? lotCodeData.reduce((total, lot, index) => {
      return total + (parseFloat(pickedQuantities[index]) || 0);
    }, 0) : 0;
    let targetValue = parseFloat(rowData.target.replace(/[^\d.-]/g, ""));
    if (totalAvailable == targetValue) {
      let picked_lot_code = lotCodeData
        .filter((_, index) => pickedQuantities[index] > 0)
        .map((lot) => lot.lot_code);

      let lot_codes = lotCodeData
        .filter((_, index) => pickedQuantities[index] > 0)
        .map((lot) => { return { code: lot.lot_code, unit_type: lot.unit_type, unit_value: pickedQuantities[lotCodeData.indexOf(lot)] } });

      handleSubmit({
        ...rowData,
        picked_lot_code: picked_lot_code.join(", "),
        lot_codes: lot_codes,
        picked_quantity: rowData.target,
      });
    } else {
      setErrorMessage("Please select the correct picked quantity.");
    }
  };

  const getTotalPickedQuantity = () => {
    if (!pickedQuantities) return 0;
    const total = pickedQuantities.reduce((total, quantity) => {
      const validQuantity = parseFloat(quantity) || 0;
      return total + validQuantity;
    }, 0);
    return total.toFixed(decimalPoints);
  };

  const totalAvailable = lotCodeData ? lotCodeData.reduce((total, lot, index) => {
    return total + (parseFloat(pickedQuantities[index]) || 0);
  }, 0) : 0;

  return (
    <>
      {loading && (
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justify="center"
          style={{ width: "100%", height: "100%" }}
        >
          <CircularProgress />
        </Grid>
      )}
      {!loading && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Pick up the Product</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Product Name : {rowData.product.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Required Quantity : {rowData.target}
                </Typography>
              </Grid>
            </Grid>
            <Paper
              elevation={0}
              square
              style={{ padding: "12px", marginBottom: "8px" }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                  <Typography className={classes.generalFormTypographyHeader}>
                    Lot Code
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.generalFormTypographyHeader}>
                    Existing Quantity
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.generalFormTypographyHeader}>
                    Picked Quantity
                  </Typography>
                </Grid>
              </Grid>
              {lotCodeData &&
                lotCodeData.map((lot, index) => (
                  <Grid container spacing={2} alignItems="center" key={index}>
                    <Grid item xs={4}>
                      <CssTextField
                        fullWidth
                        placeholder="Lot Code"
                        className={classes.generalFormTextField}
                        name="name"
                        variant="outlined"
                        value={lot.lot_code}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CssTextField
                        fullWidth
                        placeholder="Total Unit Value"
                        className={classes.generalFormTextField}
                        name="name"
                        variant="outlined"
                        value={`${lot.unit_value.toFixed(decimalPoints)} ${units[lot.unit_type]?.abbreviation || ''}`}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CssTextField
                        fullWidth
                        placeholder="PickedUp Quantity"
                        className={classes.generalFormTextField}
                        name="name"
                        variant="outlined"
                        value={pickedQuantities[index] !== undefined ? pickedQuantities[index] : ""}
                        onChange={(event) =>
                          handlePickedQuantityChange(event, index)
                        }
                        inputProps={{ min: 0, style: { WebkitAppearance: 'none', appearance: 'none' } }}
                        type="number"
                      // inputMode="numeric"
                      />
                    </Grid>
                  </Grid>
                ))}
              <Grid container spacing={2} alignItems="right">
                <Grid item xs={12}>
                  <Typography className={classes.generalFormTypographyHeader} align="right">
                    Total Picked Quantity : {`${getTotalPickedQuantity()} ${units[rowData.unit_type]?.abbreviation}`}
                  </Typography>
                </Grid>
              </Grid>
              {errorMessage && (
                <Grid container justifyContent="center" alignItems='center' style={{ marginTop: '10px' }}>
                  <Grid item xs={12}>
                    <Typography style={{ color: 'red', textAlign: 'center' }}>
                      Error: {errorMessage}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmission(rowData)}
              color="primary"
              disabled={!(totalAvailable >= parseFloat(rowData.target.replace(/[^\d.-]/g, "")))}
              variant="contained"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default FulfillmentModal;

FulfillmentModal.propTypes = {
  rowData: PropTypes.object,
  units: PropTypes.objectOf(PropTypes.object),
  finishInventory: PropTypes.objectOf(PropTypes.array),
};
