import React, { useEffect, useState } from "react";
import TabbableGroupedTable from "../../Components/GroupedTable/TabbableGroupTable";
import API from "../../Api/Api";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Traceability from "../Traceability";
import TraceabilityUtils from "../TraceabilityUtils";
import Message from "../../Components/Message";
import PropTypes from 'prop-types';
import ShippingStatusManager from './ShippingStatusManager';
import Slide from '@material-ui/core/Slide';
import { StylesContext } from "../../App";

const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//todo this could do with a redo now that things are tracked by internal lot code, a lot of optimizations available.
export default function ShippingOrderTable(props) {
  const classes = React.useContext(StylesContext);
  const utils = new TraceabilityUtils();

  const tabStatuses = {
    counted: false,
    column: 'status',
    tabs: [
      { title: 'All', value: 'All' },
      { title: 'Draft', value: 'Draft' },
      { title: 'Picking', value: 'Picking' },
      { title: 'Shipped', value: 'Shipped' },
    ]
  }

  const [shippings, setShippings] = useState(false);
  const [loading, setLoading] = useState(true);
  const [statusManagerOpen, setStatusManagerOpen] = useState(false);
  const [shippingSelected, setShippingSelected] = useState(-1);
  const [shouldClose, setShouldClose] = useState(true);
  const [message, setMessage] = useState({ ...EMPTY_MESSAGE });
  const [user, setUser] = useState(null);
  const [customers, setCustomers] = useState(false);
  const [units, setUnits] = useState(false);
  const [dummyKey, setDummyKey] = useState(0);
  const [finishInventory, setFinishInventory] = useState(false);
  const [customerAddressArr, setCustomerAddressArr] = useState(false); 

  function getUnitChoices(units, unitType) {
    const targetUnitType = units[unitType].unit_type;
    return Object.values(units)
      .filter(unit => unit.unit_type === targetUnitType)
      .map(unit => unit.pk);
  }

  useEffect(() => {
    const api = new Traceability().getFShippingAPI();
    const authApi = new API().getAuthAPI();
    const customerApi = new Traceability().getFCustomerAPI();
    const unitApi = new Traceability().getUnitsAPI();
    const customerAddressApi = new Traceability().getFCustomerAddressesAPI();

    if (props.user) {
      setUser(props.user)
    }
    else {
      authApi.getAuthedProfile().then(e => {
        setUser(e.data);
      }).catch(e => {
        //console.log(e);
      })
    }

    if (props.units) {
      let pkIndexedUnits = {};
      props.units.forEach((unitObject) => {
        pkIndexedUnits[unitObject.pk] = { ...unitObject };
      });
      setUnits(pkIndexedUnits);
    }
    else {
      utils.pkIndexObjectsFromApi(unitApi.listUnits.bind(unitApi), setUnits);
    }

    if (props.shippings) {
      setShippings([...props.shippings]);
    }
    else {
      api.listFShipping().then(e => {
        let shippingObjects = e.data;
        setShippings(shippingObjects);
      }).catch(e => {
        //console.log('BE Error: ' + e);
      })


      if (!!props.customerAddress) {
        let pkIndexedcustomerAddress = {};
        props.customerAddress.forEach((customerObject) => {
          pkIndexedcustomerAddress[customerObject.id] = { ...customerObject };
        });
        setCustomerAddressArr(pkIndexedcustomerAddress);
      }
      else {
        utils.pkIndexObjectsFromApi(
          () => customerAddressApi.listFCustomerAddresses(),
          setCustomerAddressArr,
          // Optional dataMassageFunction here if needed
        );
        // utils.idIndexObjectsFromApi(customerAddressApi.listFCustomerAddresses(parseInt(shipping.customer)).bind(customerAddressApi), setCustomerAddress);
      }

    }

    if (!!props.customers) {
      let pkIndexedCustomers = {};
      props.customers.forEach((customerObject) => {
        pkIndexedCustomers[customerObject.id] = { ...customerObject };
      });
      setCustomers(pkIndexedCustomers);
    }
    else {
      utils.idIndexObjectsFromApi(customerApi.listCustomer.bind(customerApi), setCustomers);
    }

    setLoading(false);

  }, []);

  useEffect(() => {
    const finishedInventoryApi = new Traceability().getFFinishedInventoryAPI();

    // Load finishInventory after units are loaded
    if (!!units) {
      if (props.finishInventory) {
        setFinishInventory([...props.finishInventory]);
      }
      else {
        finishedInventoryApi.listFFinishedInventory().then(e => {
          let updatedFinishedInventory = e.data.map((invenoty) => {
            const unitChoices = getUnitChoices(units, invenoty.base_unit_type);
            return {
              ...invenoty,
              unit_choices: unitChoices
            }
          })
          setFinishInventory(updatedFinishedInventory);
        }).catch(e => {
          //console.log('BE Error: ' + e);
        })
      }
    }
  }, [units]);

  const shippingColumns = [
    { title: "Shipping Id", field: "shippingId" },
    { title: "Customer", field: "Customer" },
    { title: "Status", field: 'status' },
    { title: "Shipping Date", field: 'shippingDate' },
  ];

  function formatTableData(tableData) {
    let formattedTableData = [];

    tableData.forEach((dataPoint) => {
      let formattedPoint = {};
      formattedPoint.shippingId = dataPoint.id;

      formattedPoint['Customer'] = dataPoint.customer ? customers[dataPoint.customer]?.name : '';

      formattedPoint.shippingDate = dataPoint.shipment_date ? utils.dateObjToString(dataPoint.shipment_date) : '';

      formattedPoint.status = dataPoint.status;
      formattedPoint.id = dataPoint.id;

      formattedTableData.push(formattedPoint);
    });

    return formattedTableData;
  }

  /**
   * Handles keeping the list up to date with the back end, including updating all lines.
   * 
   * @param {JSON} response - a response object from the server
   */
  function onSave(response, index) {
    setLoading(true)

    let updatedShippingPk = response.data.id;
    let updatedShippings = [...shippings];
    let newShipping = { ...response.data }
    console.log("onSave >> response.data >> ", response.data)

    let itemIndex;
    if (!index && index !== 0) {
      itemIndex = updatedShippings.findIndex(shipping => {
        return shipping.id === updatedShippingPk;
      });
    }
    else {
      itemIndex = index;
    }

    if (itemIndex > -1) {
      updatedShippings.splice(itemIndex, 1, newShipping);
    }
    else {
      updatedShippings.push(newShipping);
      itemIndex = updatedShippings.length - 1;
    }
    console.log("onSave >> updatedShippings >> ", updatedShippings);
    setShippings(updatedShippings);

    setLoading(false)

    setShippingSelected(itemIndex);


    setDummyKey(dummyKey + 1);
  }

  function openStatusManager(event, rowData) {
    let newIndex = utils.matchOnId(shippings, rowData.id, '_index_')
    if (shippingSelected != newIndex) {
      setShippingSelected(newIndex);
    }

    setStatusManagerOpen(true);
  }

  function closeFulfillingModal() {
    if (!shouldClose) {
      if (!window.confirm("Are you sure you want to close without saving?")) {
        return;
      }
    }
    cancel();
  }

  function cancel() {
    setShippingSelected(-1);
    setStatusManagerOpen(false);
  }
  
  return (
    <>
      {(!shippings || !customers || !units || !finishInventory || !customerAddressArr) &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }


      {(shippings && customers && (shippingSelected == -1) && units && finishInventory && customerAddressArr) &&
        <Paper elevation={0} square className={classes.generalListPaperContainer} >
          <Grid container spacing={0} style={{ padding: '0px' }}>
            <Grid item xs={12}>
              <TabbableGroupedTable
                tabStatuses={tabStatuses}
                data={formatTableData(shippings)}
                options={{
                  pageSize: 10,
                }}
                columns={shippingColumns}
                draggable={!loading}
                title={'Shipping'}
                localization={{
                  body: {
                    emptyDataSourceMessage: 'Click New Shipment to create a new shipment.',
                  }
                }}

                actions={
                  [
                    {
                      icon: (props) => (
                        <Button size="small" color="primary" variant="outlined" aria-label="add" className={classes.tabTableFreeAction}>
                          {'New Shipment'}
                        </Button>
                      ),
                      tooltip: 'Create a New Shipment',
                      isFreeAction: true,
                      onClick: (event) => { setShippingSelected(-2); setStatusManagerOpen(true); }
                    },
                    rowData => ({
                      icon: (props) => (
                        <Button size="small" color="primary" variant="outlined" aria-label="add">
                          {utils.statusActionCipher(rowData.status, false)}
                        </Button>
                      ),
                      tooltip: 'Open Manager',
                      onClick: (event, rowData) => openStatusManager(event, rowData)
                    }),
                  ]
                }

                onRowClick={(event, rowData) => openStatusManager(event, rowData)}
              />
            </Grid>
          </Grid>

          <Message
            open={message.open}
            message={message.message}
            severity={message.status}
            vertical="bottom"
            horizontal="left"
            handleClose={() => { setMessage({ ...message, open: false }); setLoading(false) }}
          />
        </Paper>
      }

      {shippings && customers && finishInventory &&
        <Dialog fullScreen open={statusManagerOpen} onClose={closeFulfillingModal} TransitionComponent={Transition}>
          <DialogContent className={classes.generalFormDialogueContainer}>
            <ShippingStatusManager
              shipping={shippingSelected >= 0 ? shippings[shippingSelected] : { status: 'Draft', new_shipment_products: [] }}
              setShouldClose={setShouldClose}
              user={user}
              onSave={onSave}
              closeModal={closeFulfillingModal}
              cancel={cancel}
              // activeTraceability={props.activeTraceability}
              customers={customers}
              customerAddressArr={customerAddressArr}
              // suppliers={suppliers}
              units={units}
              // internalLotCodes={internalLotCodes}
              finishInventory={finishInventory}
            // skus={skus}
            // inventoryLocations={inventoryLocations}
            />
          </DialogContent>
        </Dialog>
      }
    </>
  )
}

ShippingOrderTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  customers: PropTypes.arrayOf(PropTypes.object),
  suppliers: PropTypes.arrayOf(PropTypes.object),
  // skus: PropTypes.arrayOf(PropTypes.object),
  units: PropTypes.objectOf(PropTypes.object),
  // inventoryLocations: PropTypes.objectOf(PropTypes.object),
  activeTraceability: PropTypes.object,
}