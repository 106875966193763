import React, { useEffect, useState, useContext } from "react";
import { OrgContext } from "hooks/useOrganization"

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink } from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import List from "@material-ui/core/List";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import DashboardIcon from "@material-ui/icons/Dashboard";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import GroupIcon from "@material-ui/icons/Group";
import FolderIcon from "@material-ui/icons/Folder";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import SettingsIcon from "@material-ui/icons/Settings";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import PollIcon from '@material-ui/icons/Poll';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

const useStyles = makeStyles((theme) => ({
  divContainer: {
    marginBottom: "10px",
  },

  active: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
  },

  activeCollapsedMenu: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
  },

  listItemText: {
    position: "relative",
    fontSize: "14px", //Insert your required size
    color: "#687895",
    fontWeight: 500,
    fontFamily: "Roboto",
  },

  listMenuItemText: {
    position: "relative",
    fontSize: "12px", //Insert your required size
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: "20px",
  },
  subListMenuItemText: {
    position: "relative",
    fontSize: "12px", //Insert your required size
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: "20px",
  },

  arrowIcon: {
    backgroundColor: "transparent",
    fontSize: "20px",
    minWidth: null,
  },

  button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  settingsIconButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    width: "20px",
    height: "20px",
    paddingLeft: "18px",
  },
  sideMenuMainIcons: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  },
  subMenuItems: {
    width: "100%",
    height: "20px",
    marginBottom: "10px",
  },
  sideMenu: {
    height: "100%",
    maxHeight: 420,
    overflowY: "auto",
    marginTop: "12px",
    paddingTop: 0,
    width: "198px",
  },
  sideMenuTitle: {
    marginLeft: "18px",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 500,
    paddingTop: "11px",
    paddingBottom: "16px",
    backgroundColor: "transparent",
    color: "#687895",
  },

  selected: {
    "& svg": {
      fontSize: "20px",
    },
    color: "#18bff6",
    backgroundColor: null,
    minWidth: '56px',
  },

  notselected: {
    "& svg": {
      fontSize: "20px",
    },
    color: "#687895",
    minWidth: "20px",
    backgroundColor: null,
  },

  settingsList: { width: "100%", paddingBottom: "12px" },

  notselectedNoSideMenu: {
    "& svg": {
      fontSize: "20px",
    },
    color: "#687895",
    backgroundColor: null,
  },
}));

function NavList(props) {
  const classes = useStyles();

  const fullPath2 = useLocation().pathname;
  const primaryKeyInUrl = fullPath2.split("/");
  const isSuperuser = props.user && props.user.user.is_superuser;
  const org = useContext(OrgContext);

  useEffect(() => {
    if (primaryKeyInUrl[1] === "traceability") {
      //if (primaryKeyInUrl[2]) {
      setHasSubMenu(traceabilityMenu);
      setSubMenuTitle("Traceability");
      //}
    }
    if (primaryKeyInUrl[1] === "haccp_plans") {
      if (primaryKeyInUrl[2]) {
        setHasSubMenu(haccpPlanMenu);
        setSubMenuTitle("Haccp Plans");
      }
    }

    //if (primaryKeyInUrl[1] === "dashboard") {
    //  if (primaryKeyInUrl[2]) {
    //    setHasSubMenu(dashboardMenu);
    //    setSubMenuTitle("Dashboard");
    //  }
    //}

    if (primaryKeyInUrl[1] === "plans") {
      if (primaryKeyInUrl[2]) {
        setHasSubMenu(planMenu);
        setSubMenuTitle("Plan Manager");
      }
    }

    if (primaryKeyInUrl[1] === "records") {
      if (primaryKeyInUrl[2]) {
        setHasSubMenu(recordMenu);
        setSubMenuTitle("Reports");
      }
    }

    if (primaryKeyInUrl[1] === "admin_tools" && isSuperuser) {
      if (primaryKeyInUrl[2]) {
        setHasSubMenu(adminToolsMenu);
        setSubMenuTitle("Admin Tools");
      }
    }

    if (primaryKeyInUrl[1] === "my_tasks") {
      if (primaryKeyInUrl[2]) {
        setHasSubMenu(myTasksMenu);
        setSubMenuTitle("My Tasks");
      }
    }

    if (primaryKeyInUrl[1] === "log_entries") {
      setHasSubMenu(null);
      setSubMenuTitle(null);
    }



  }, [fullPath2]);

  //To add a nested menu, define a Menu object below
  const traceabilityMenu = [

    {
      name: "approved_information",
      label: "Approved Information",
      path: `approved_information`,
      id: 12,
    },
    /*{
      name: 'inventory_locations',
      label: "Inventory Location",
      path: `inventory_locations`,
      id: 1,
    },*/
    {
      name: "orders",
      label: "Receiving",
      path: `orders`,
      id: 6,
    },
    {
      name: "raw_inventory",
      label: "Raw Inventory",
      path: `raw_inventory`,
      id: 14,
    },
    {
      name: "production",
      label: "Production",
      path: `production`,
      id: 8,
    },
    {
      name: "finished_inventory",
      label: "Finished Inventory",
      path: `finished_inventory`,
      id: 15,
    },
    {
      name: "shipping",
      label: "Shipping",
      path: `shipping`,
      id: 9,
    },
    // {
    //   name: "recalls",
    //   label: "Recall Manager",
    //   path: `recalls`,
    //   id: 13,
    // },
    /*{
      name: 'units',
      label: "Units",
      path: `units`,
      id: 10,
    },*/
  ];

  const haccpPlanMenu = [
    {
      name: "details",
      label: "Plan Details",
      path: `/haccp_plans/${primaryKeyInUrl[2]}/product/details`,
    },
    {
      name: "description",
      label: "Product Description",
      path: `/haccp_plans/${primaryKeyInUrl[2]}/product/description`,
    },
    {
      name: "formulation",
      label: "Formulation",
      path: `/haccp_plans/${primaryKeyInUrl[2]}/product/formulation`,
    },
    {
      name: "processes",
      label: "Processes",
      path: `/haccp_plans/${primaryKeyInUrl[2]}/product/processes`,
    },
    {
      name: "process_flow",
      label: "Process Flow Diagram",
      path: `/haccp_plans/${primaryKeyInUrl[2]}/product/process_flow`,
    },
    {
      name: "traffic_flow",
      label: "Traffic Flow Diagram",
      path: `/haccp_plans/${primaryKeyInUrl[2]}/product/traffic_flow`,
    },
    {
      name: "hazards",
      label: "Hazard Analysis",
      path: `/haccp_plans/${primaryKeyInUrl[2]}/product/hazard_analysis/hazards`,
    },
    {
      name: "significant_hazards",
      label: "Significant Hazards",
      path: `/haccp_plans/${primaryKeyInUrl[2]}/product/hazard_analysis/significant_hazards`,
    },
    {
      name: "export",
      label: "Export",
      path: `/haccp_plans/${primaryKeyInUrl[2]}/product/export`,
    },
  ];

  const dashboardMenu = [
    {
      name: "onboarding",
      label: "Onboarding",
      path: "/dashboard/onboarding",
    },
    //{
    //  name: "monitoring",
    //  label: "Monitoring",
    //  path: "/dashboard/monitoring",
    //},
    //{
    //  name: "operations",
    //  label: "Operations",
    //  path: "/dashboard/operations",
    //},
  ];

  const myTasksMenu = [
    {
      name: "monitoring",
      label: "Monitoring",
      path: "/my_tasks/monitoring",
    },
    {
      name: "deviations",
      label: "Deviations",
      path: "/my_tasks/deviations",
    },
    {
      name: "verification",
      label: "Verification",
      path: "/my_tasks/verification",
    },
  ];

  const planMenu = [
    {
      name: "active",
      label: "My Plan",
      path: "/plans/active",
    },
    {
      name: "manager",
      label: "Program Editor",
      path: "/plans/manager/program",
    },
    {
      name: "manager",
      label: "Event Editor",
      path: "/plans/manager/events",
    },
    {
      name: "manager",
      label: "Task Editor",
      path: "/plans/manager/tasks",
    },
    {
      name: "documents",
      label: "Documents",
      path: "/plans/documents",
    },
    /*{
     name: "haccp_plans",
     label: "HACCP Plans",
     path: "/plans/haccp_plans",
    },*/
    {
      name: "manager",
      label: "HACCP Plans",
      path: "/plans/manager/haccp_plans",
    },
    {
      name: "approved_information",
      label: "Approved Information",
      path: "/plans/approved_information",
    }
  ];

  const recordMenu = [
    {
      name: "monitoring_records",
      label: "Monitoring Records",
      path: "/records/monitoring_records",
    },
    // {
    //   name: "verification_records",
    //   label: "Verification Records",
    //   path: "/records/verification_records",
    // },
    {
      name: "deviation_records",
      label: "Deviation Records",
      path: "/records/deviation_records",
    },
    {
      name: "audit",
      label: "Audits",
      path: '/records/audit'
    }
  ];

  const adminToolsMenu = [
    {
      name: "all_plans",
      label: "Plan Management",
      path: "/admin_tools/all_plans",
    },
    {
      name: "add_org",
      label: "New Team",
      path: "/admin_tools/add_org",
    }
    //{
    //  name: "program_templates",
    //  label: "Program Templates",
    //  path: "/admin_tools/program_templates",
    //},

    //{
    //  name: "monitoring",
    //  label: "Monitoring Manager",
    //  path: "/admin_tools/monitoring",
    //},
  ];

  const links = [
    {
      name: "dashboard",
      label: "Dashboard",
      icon: <DashboardIcon />,
      to: "/dashboard/onboarding",
    },
    {
      name: "my_tasks",
      label: "My Tasks",
      icon: <AssignmentTurnedInIcon />,
      to: "/my_tasks/monitoring",
    },
    {
      name: "all_tasks",
      label: "Tasks",
      icon: <AssignmentIcon />,
      to: "/all_tasks/",
    },
    {
      name: "logs",
      label: "Logs",
      icon: <FormatListBulletedIcon />,
      to: "/logs",
    },
    {
      name: "plans",
      label: "Plan Manager",
      icon: <FolderIcon />,
      to: "/plans/active",
    },
    {
      name: "records",
      label: "Records",
      icon: <PollIcon />,
      to: `/records/monitoring_records`,
    },
    {
      name: "team",
      label: "Team",
      icon: <GroupIcon />,
      to: "/team",
    },
  ];

  if (isSuperuser) {
    links.push({
      name: "admin_tools",
      label: "Admin Tools",
      icon: <SupervisedUserCircleIcon />,
      to: "/admin_tools/",
    });
  }
  if (props.user
    && props.user.user
    && (props.user.user.email === 'iinderjitbedi@gmail.com'
      || props.user.user.email === 'sumit@quantumsystem.in'
      || props.user.user.email === 'sean@joinfoodbyte.com'
      || props.user.user.email === 'beth@joinfoodbyte.com'
      || props.user.user.email === 'shivam.sharma@quantumsystem.in')) {
    links.push({
      name: "traceability",
      label: "Traceability",
      icon: <LocalShippingIcon />,
      to: `/traceability`,
    })
  }
  function menuGoBack() {
    setHasSubMenu(null);
  }

  const [selectedIndex, setSelectedIndex] = React.useState(6);

  //Handle Main Menu item clicks
  const handleListItemClick = (event, index, link) => {
    setSelectedIndex(index);
    if (props.setMobileMenuOpen) {
      props.setMobileMenuOpen(false);
    }

    if (link.name === "dashboard") {
      setHasSubMenu(dashboardMenu);
      setSubMenuTitle("Dashboard");
    }

    else if (link.name === "my_tasks") {
      setHasSubMenu(myTasksMenu);
      setSubMenuTitle("My Tasks");
    }

    else if (link.name === "all_tasks") {
      setHasSubMenu(null);
      setSubMenuTitle(null);
    }

    else if (link.name === "traceability") {
      setHasSubMenu(traceabilityMenu);
      setSubMenuTitle("Traceability");
    }

    else if (link.name === "plans") {
      setHasSubMenu(planMenu);
      setSubMenuTitle("Plan Manager");
    }



    else if (link.name === "records") {
      setHasSubMenu(recordMenu);
      setSubMenuTitle("Records");
    }

    else if (link.name === "team") {
      setHasSubMenu(null);
      setSubMenuTitle(null);
    }

    else if (link.name === "logs") {
      setHasSubMenu(null);
      setSubMenuTitle(null);
    }

    else if (link.name === "admin_tools") {
      setHasSubMenu(adminToolsMenu);
      setSubMenuTitle("Admin Tools");
    }
  };

  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);


  //Handle Nested Menu item clicks
  const handleMenuItemClick = (event, index) => {
    setSelectedMenuIndex(index);
    if (props.setMobileMenuOpen) {
      props.setMobileMenuOpen(false);
    }
  };

  const fullPath = useLocation().pathname;
  const pathArray = fullPath.split("/");

  //Selected Index of Main Menu
  useEffect(() => {
    let selectedIndex = links.findIndex((item) => {
      return item.name === pathArray[1];
    });
    if (selectedIndex != null) {
      setSelectedIndex(selectedIndex);
    }
  }, [fullPath]);

  //Selected Index of Nested Menu
  useEffect(() => {
    if (pathArray[1] === "traceability") {
      const selectedMenuIndex = traceabilityMenu.findIndex((item) => {
        return item.name === pathArray[3];
      });
      if (selectedMenuIndex != null) {
        setSelectedMenuIndex(selectedMenuIndex);
      }
    } else if (pathArray[1] === "haccp_plans") {
      const selectedMenuIndex = haccpPlanMenu.findIndex((item) => {
        if (pathArray.length >= 6) {
          return item.name === pathArray[5];
        } else {
          return item.name === pathArray[4];
        }
      });

      if (selectedMenuIndex != null) {
        setSelectedMenuIndex(selectedMenuIndex);
      }
    } else if (pathArray[1] === "dashboard") {
      const selectedMenuIndex = dashboardMenu.findIndex((item) => {
        return item.name === pathArray[2];
      });

      if (selectedMenuIndex != null) {
        setSelectedMenuIndex(selectedMenuIndex);
      }
    } else if (pathArray[1] === "plans") {
      const selectedMenuIndex = planMenu.findIndex((item) => {
        return item.name === pathArray[2];
      });

      if (selectedMenuIndex != null) {
        setSelectedMenuIndex(selectedMenuIndex);
      }
    } else if (pathArray[1] === "admin_tools" && isSuperuser) {
      const selectedMenuIndex = adminToolsMenu.findIndex((item) => {
        return item.name === pathArray[2];
      });

      if (selectedMenuIndex != null) {
        setSelectedMenuIndex(selectedMenuIndex);
      }
    } else if (pathArray[1] === "my_tasks") {
      const selectedMenuIndex = myTasksMenu.findIndex((item) => {
        return item.name === pathArray[2];
      });

      if (selectedMenuIndex != null) {
        setSelectedMenuIndex(selectedMenuIndex);
      }
    } else if (pathArray[1] === "records") {
      const selectedMenuIndex = recordMenu.findIndex((item) => {
        return item.name === pathArray[2];
      });

      if (selectedMenuIndex != null) {
        setSelectedMenuIndex(selectedMenuIndex);
      }
    } else if (pathArray[1] === "logs" || pathArray[1] === "log_entries") {
      const selectedMenuIndex = links.findIndex(item => item.name === "logs");

      if (selectedMenuIndex != null) {
        setSelectedIndex(selectedMenuIndex);
      }
    }
  }, [fullPath]);

  const [hasSubMenu, setHasSubMenu] = useState(null);
  const [subMenuTitle, setSubMenuTitle] = useState(null);

  const getListItem = (link, index) => {
    let isSelected = selectedIndex === index;

    if (!org.has_traceability && link.name === "traceability") {
      return;
    }

    return (
      <div key={index}>
        {!hasSubMenu && (
          <div className={classes.divContainer}>
            <ListItem
              disableRipple
              disableTouchRipple
              classes={{ button: classes.button }}
              dense
              button
              key={index}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index, link)}
              component={NavLink}
              to={link.to}
              style={{
                height: "40px",
                width: 260,
                paddingLeft: '0px',
                padding: '0px',
                backgroundColor: "#F8F8F8",
                borderLeft: isSelected
                  ? "2px solid #18bff6"
                  : "2px solid #F8F8F8",
              }}
            >
              <ListItemIcon
                style={{
                  width: '58px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                classes={{
                  root:
                    selectedIndex === index
                      ? classes.selected
                      : classes.notselectedNoSideMenu,
                }}
              >
                {link.icon}
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }}>
                {link.label}
              </ListItemText>
            </ListItem>{" "}
          </div>
        )}

        {hasSubMenu && (
          <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px' }}>
            <ListItem
              dense
              disableRipple
              disableTouchRipple
              button
              key={index}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index, link)}
              component={NavLink}
              to={link.to}
              style={{
                borderLeft: isSelected
                  ? "2px solid #18bff6"
                  : "2px solid #F8F8F8",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                padding: '0px',
                display: "flex",
                justifyContent: "center",
                height: "40px",
                backgroundColor: "#F8F8F8",
              }}
            >
              <ListItemIcon
                style={{
                  width: '60px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                classes={{
                  root:
                    selectedIndex === index
                      ? classes.selected
                      : classes.notselected,
                }}
              >
                {link.icon}
              </ListItemIcon>
            </ListItem>
          </div>
        )}
      </div>
    );
  };

  const getMenuItems = (link, index) => {
    let isSelected = selectedMenuIndex === index;

    if (subMenuTitle === 'Traceability' && !org.has_traceability && link.name !== "approved_information") {
      return;
    }

    return (
      <div key={index}>
        {hasSubMenu && (
          <div
            className={classes.subMenuItems}
          >
            <ListItem
              dense
              button
              key={index}
              selected={selectedMenuIndex === index}
              onClick={(event) => handleMenuItemClick(event, index)}
              component={NavLink}
              to={link.path}
              style={{
                width: "100%",
                height: "40px",
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: "#F8F8F8",
                color: isSelected ? "#18bff6" : "#949EB1",
              }}
            >
              <ListItemText
                classes={{ primary: classes.subListMenuItemText }}
              >
                {link.label}
              </ListItemText>
            </ListItem>{" "}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {!hasSubMenu && org && (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <div style={{ width: 260 }}>
            <List dense style={{ width: "59px" }}>
              {links.map(getListItem)}
            </List>
          </div>

          <div style={{ width: 260 }}>
            <List
              dense
              component="nav"
              aria-label="org-select"
              className={classes.settingsList}
            >
              <ListItem
                button
                className={classes.button}
                disableRipple
                disableTouchRipple
                id="profile"
                aria-haspopup="true"
                aria-controls="lock-menu"
                aria-label="org-select"
                onClick={props.handleClick}
                style={{
                  height: "40px",
                  width: 260,
                  paddingLeft: '0px',
                  padding: '0px',
                  backgroundColor: "#F8F8F8",
                  borderLeft: "2px solid #F8F8F8",
                }}
              >
                <ListItemIcon
                  style={{
                    width: '60px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  className={classes.arrowIcon}
                >
                  <SettingsIcon className={classes.arrowIcon} />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.listItemText }}>
                  Settings
                </ListItemText>
              </ListItem>
            </List>
          </div>
        </div>
      )}
      {hasSubMenu && org && (
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: '60px' }} className={classes.sideMenuMainIcons}>
            <List dense style={{ width: "59px" }}>
              {links.map(getListItem)}
            </List>
            {props.open && props.user && (
              <List
                dense
                component="nav"
                aria-label="org-select"
                style={{
                  height: "40px",
                  width: "59px",
                  padding: "0px",
                  marginBottom: '16px',
                  backgroundColor: "#F8F8F8",
                }}
              >
                <ListItem
                  button
                  disableRipple
                  disableTouchRipple
                  id="profile"
                  aria-haspopup="true"
                  aria-controls="lock-menu"
                  aria-label="org-select"
                  onClick={props.handleClick}
                  style={{
                    height: "40px",
                    width: 59,
                    justifyContent: 'center',
                    paddingLeft: '0px',
                    paddingBottom: '20px',
                    padding: '0px',
                    backgroundColor: "#F8F8F8",
                  }}
                >
                  <ListItemIcon
                    style={{
                      width: '60px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    className={classes.arrowIcon}>
                    <SettingsIcon className={classes.arrowIcon} />
                  </ListItemIcon>
                </ListItem>
              </List>
            )}
          </div>
          <div style={{ width: '200px' }}>
            <Grid
              container
              style={{
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                height: "40px",
              }}
            >
              <Grid item xs={10}>
                {subMenuTitle && (
                  <Typography className={classes.sideMenuTitle}>
                    {subMenuTitle}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  disableRipple
                  style={{
                    float: "right",
                    paddingTop: "9px",
                    paddingBottom: "11px",
                    paddingRight: "11px",
                    backgroundColor: "transparent",
                  }}
                  onClick={menuGoBack}
                >
                  <ArrowBackIcon className={classes.arrowIcon}></ArrowBackIcon>
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.sideMenu}>
              {hasSubMenu.map(getMenuItems)}
            </Grid>
          </div>
        </div>
      )}
    </>
  );
}

export default NavList;