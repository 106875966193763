import React, { useState, useEffect } from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Traceability from "../Traceability";
import Message from "../../Components/Message";
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import NoEncryptionOutlinedIcon from '@material-ui/icons/NoEncryptionOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import TraceabilityUtils from "../TraceabilityUtils";
import NewManOrderForm from "./NewManOrderForm";
import NewManOrderDetailForm from "./NewManOrderDetailForm";
import PropTypes from 'prop-types';
import {StylesContext} from "../../App";


export default function ManOrderStatusManager(props) {
  const utils = new TraceabilityUtils();
  const classes = React.useContext(StylesContext);

  const [isBlocking, setIsBlocking] = useState(false);
  const [message, setMessage] = useState(utils.emptyMessage());
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [viewingStatus, setViewingStatus] = useState(props.manOrderSheet.status);
  const [manOrderToShow, setManOrderToShow] = useState(props.manOrderSheet);
  const [dummyKey, setDummyKey] = useState(0);
  const [pkToUse, setPkToUse] = useState(false);
  const [submittingStatus, setSubmittingStatus] = useState(false);

  useEffect(() => {
    setManOrderToShow(props.manOrderSheet);
    setViewingStatus(props.manOrderSheet.status);
    //setDummyKey(dummyKey + 1);

  }, [props.manOrderSheet])

  useEffect(() => {
    if(!props.manOrderSheet.previous_states) {
      return;
    }

    let prevStates = JSON.parse('{' + props.manOrderSheet.previous_states.substring(0, props.manOrderSheet.previous_states.length - 1) + '}');

    if( props.manOrderSheet.status == viewingStatus) {
      setManOrderToShow(props.manOrderSheet);
    }
    else if(prevStates[viewingStatus]) {
      setManOrderToShow(prevStates[viewingStatus])
    }
    else {
      setManOrderToShow({sku_instances:[]})
    }

  }, [viewingStatus])

  function handleStatusSubmit(pk, setSubmitting) {
    setSubmittingStatus(true)
    const api = new Traceability().getBatchAPI();

    if (props.manOrderSheet.status != "Complete" && !props.manOrderSheet.status != "CANC") {
      api.updateBatchStatus(pk).then(response => {
        setMessage({
          open: true,
          message: 'Updated Successfully',
          status: 'success',
        });

        if(response.data.status != 'Complete') {
          props.setManOrderSelected(response.data);
          setViewingStatus( response.data.status );
        }
        setPkToUse(response.data.pk);

        if (props.onSave) {
          props.onSave(response);
        }

        if(setSubmitting) {
          setSubmitting(false);
        }
        setSubmittingStatus(false);
      }).catch(error => {
        //console.log('Error Response: ' + JSON.stringify(error));
        if(setSubmitting) {
          setSubmitting(false);
        }
        setMessage({ open: true, message: 'Save Failed: ' + utils.formatError(error), status: 'error' });
        setSubmittingStatus(false);
      });

    }
  }


  function handleStatusClick(status, acceptedStatuses) {
    //Try order status first to prevent issues arising from multiple statuses being tied to a single button.
    if(acceptedStatuses.indexOf(props.manOrderSheet.status) != -1){
      setViewingStatus(props.manOrderSheet.status)
    }
    else {
      setViewingStatus(status)
    }
  }

  function getClassStyle(acceptedStatuses, recordStatus, viewingStatus) {
    if(acceptedStatuses.indexOf(recordStatus) != -1 && acceptedStatuses.indexOf(viewingStatus) != -1) {
      return classes.statusBothSelected;
    }
    else if(acceptedStatuses.indexOf(recordStatus) != -1) {
      return classes.statusRecordSelected;
    }
    else if(acceptedStatuses.indexOf(viewingStatus) != -1) {
      return classes.statusViewingSelected;
    }
    else{
      return classes.statusUnselected;
    }
  }

  function getButtonIcon(acceptedStatuses, recordStatus, viewingStatus) {
    if(acceptedStatuses.indexOf(recordStatus) != -1 && acceptedStatuses.indexOf(viewingStatus) != -1) {
      return (<LockOutlinedIcon style={{ float: 'center' }} />);
    }
    else if(acceptedStatuses.indexOf(recordStatus) != -1) {
      return (<LockOpenOutlinedIcon style={{ float: 'center' }} />);
    }
    else if(acceptedStatuses.indexOf(viewingStatus) != -1) {
      return (<NoEncryptionOutlinedIcon style={{ float: 'center' }} />);
    }
    else {
      return (<AccessAlarmsIcon style={{ float: 'center' }} />);
    }
  }

  function getStatusPercent(currentStep, stage) {
    const steps = { 
      'Draft' : 1, 
      'SCHE' : 2,
      'Ongoing' : 2,
      'Complete' : 3,
      'CANC' : 3,
    };

    if(steps[currentStep] < stage) {
      return 0;
    }
    else if (steps[currentStep] > stage) {
      return 100;
    }
    else {
      return 50;
    }
  }

  return (
    <>
      <Paper elevation={0} className={classes.generalFormPaperHeader}>
        {/* This grid acts has header to both forms. */}
        <Grid container spacing={3}>

          <Grid container item justify='center' alignItems='center' spacing={3} className={classes.generalContainerGridHead}>
            <Grid container item justify='center' alignItems='center' spacing={3} style={{width: '50%'}}>
              
              <Grid item >
                <Typography align='center' noWrap className={classes.statusText}>
                  {'Draft'}
                </Typography>
                <IconButton 
                  variant="outlined" 
                  className={getClassStyle(['Draft'], props.manOrderSheet.status, viewingStatus)}
                  onClick={ () => {handleStatusClick('Draft', ['Draft'])} }
                >
                  <div className={classes.statusRoot}>
                    {getButtonIcon(['Draft'], props.manOrderSheet.status, viewingStatus)}
                  </div>
                </IconButton>
              </Grid>
              <Grid item xs={2} className={classes.statusLine}>
                <LinearProgress variant="determinate" value={getStatusPercent(viewingStatus, 1)} />
              </Grid>

              <Grid item >
                <Typography align='center' noWrap className={classes.statusText}>
                  {'Ongoing'}
                </Typography>
                <IconButton 
                  variant="outlined" 
                  align='center'
                  className={getClassStyle(['SCHE', 'Ongoing'], props.manOrderSheet.status, viewingStatus)}
                  onClick={ () => {handleStatusClick('Ongoing', ['SCHE', 'Ongoing'])} }
                >
                  <div className={classes.statusRoot}>
                    {getButtonIcon(['SCHE', 'Ongoing'], props.manOrderSheet.status, viewingStatus)}
                  </div>
                </IconButton>
              </Grid>
              <Grid item xs={2} className={classes.statusLine}>
                <LinearProgress variant="determinate" value={getStatusPercent(viewingStatus, 2)} />
              </Grid>

              <Grid item >
                <Typography align='center' noWrap className={classes.statusText}>
                  {'Complete'}
                </Typography>
                <IconButton 
                  variant="outlined" 
                  className={getClassStyle(['CANC', 'Complete'], props.manOrderSheet.status, viewingStatus)}
                  onClick={ () => {handleStatusClick('Complete', ['CANC', 'Complete'])} }
                >
                  <div className={classes.statusRoot}>
                    {getButtonIcon(['CANC', 'Complete'], props.manOrderSheet.status, viewingStatus)}
                  </div>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid item style={{ width: '100%', height: '0px', float: 'right', position: 'relative', bottom: '130px' }}>
            <IconButton style={{ float: 'right' }} onClick={props.closeModal}>
              <CancelIcon style={{ float: 'right' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>

      {/* Each form has its own body and footer. */}
      {(viewingStatus == 'Draft') &&
        <Grid item xs={12}>
          <NewManOrderForm
            onSave={props.onSave}//todo why does this one not work with history??
            needToConsolidate={viewingStatus == props.manOrderSheet.status}
            disabled={viewingStatus != props.manOrderSheet.status}
            pkToUse={pkToUse}
            setShouldClose={props.setShouldClose}
            closeModal={props.closeModal}
            cancel={props.cancel}
            user={props.user}
            units={props.units}
            production={props.production}
            formulation={props.formulation}
            formulations = {props.formulations}
            indexedFormulations={props.indexedFormulations}
            internalLotCodes={props.internalLotCodes}
            manOrderSheet={manOrderToShow}
            activeTraceability={props.activeTraceability}
            setReturnMessage={props.setMessage}
            setManOrderSelected={props.setManOrderSelected}
            setFormulationSelected={props.setFormulationSelected}
            productionLotCodeDict={props.productionLotCodeDict}
          />
        </Grid>
      }

      {(viewingStatus != 'Draft') &&
        <>
          {props.manOrderSheet.status == 'SCHE' &&
            <Grid item container xs={12} alignItems="center" style={{backgroundColor: '#18BFF6', position: "relative", width: '100%', height: 50, zIndex: 2}}>
              <Grid item container xs={6} alignItems="center" justify="flex-end">
                <Typography style={{color: 'white', fontSize: '16px', fontStyle: 'normal', fontWeight: 500, fontSize: '16px', lineHeight: '24px'}}>
                  This M/O is ready to start production
                </Typography>
              </Grid>
              <Grid item container xs={6} alignItems="center" justify="flex-start">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginLeft: '8px', color: 'white', border: '1px solid white', display: 'block' }}
                  onClick={() => { handleStatusSubmit( pkToUse ? pkToUse : props.manOrderSheet.pk ) }}
                  disabled={submittingStatus}
                >
                  Start Production
                </Button>
              </Grid>
            </Grid>
          }
          <Grid item xs={12} style={{ opacity: props.manOrderSheet.status == 'SCHE' ? 0.5 : 1, filter: viewingStatus == "SCHE" ? 'blur(8px)' : null }}>
            <NewManOrderDetailForm
              onSave={props.onSave}
              pkToUse={pkToUse}
              needToConsolidate={viewingStatus == props.manOrderSheet.status}
              setShouldClose={props.setShouldClose}
              closeModal={props.closeModal}
              cancel={props.cancel}
              user={props.user}
              units={props.units}
              skus={props.skus}
              formulaSkus={props.formulaSkus}
              formulation={props.formulation}
              formulations = {props.formulations}
              formulaSku={props.formulaSku}
              internalLotCodes={props.internalLotCodes}
              manOrderSheet={manOrderToShow}
              activeTraceability={props.activeTraceability}
              disabled={props.manOrderSheet.status == 'SCHE' || props.manOrderSheet.status == 'Complete' || viewingStatus != props.manOrderSheet.status}
              faded={props.manOrderSheet.status == 'SCHE'}
              setManOrderSelected={props.setManOrderSelected}
              setFormulationSelected={props.setFormulationSelected}
              setReturnMessage={props.setMessage}
              productionLotCodeDict={props.productionLotCodeDict}
            />
          </Grid>
        </>
      }

      <Message
        open={message.open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="left"
        handleClose={() => { setMessage({ ...message, open: false }) }}
      />
    </>
  )
}

ManOrderStatusManager.propTypes = {
  setShouldClose: PropTypes.func,
  setReturnMessage: PropTypes.func,
  onSave: PropTypes.func,
  manOrderSheet: PropTypes.object,
  closeModal: PropTypes.bool,
  suppliers: PropTypes.objectOf(PropTypes.object),
  customers: PropTypes.objectOf(PropTypes.object),
  production: PropTypes.objectOf(PropTypes.object),
  units: PropTypes.objectOf(PropTypes.object),
  inventoryLocations: PropTypes.objectOf(PropTypes.object),
  cancel: PropTypes.func,
  activeTraceability: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}