import React, { useEffect, useState } from "react";

import TabbableGroupedTable from "../../Components/GroupedTable/TabbableGroupTable";
import API from "../../Api/Api";
import InventoryLocationForm from "../InventoryLocation/InventoryLocationForm";
import CustomerForm from "../Customer/CustomerForm";
import SupplierForm from "../Supplier/SupplierForm";
import SKUForm from "../SKUs/SKUForm";
import FormulaSKUForm from "../FormulationSkus/FormulaSKUForm";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Traceability from "../Traceability";
import TraceabilityUtils from "../TraceabilityUtils";
import Message from "../../Components/Message";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Paper from "@material-ui/core/Paper";
import { StylesContext } from "../../App";
import FProductForm from "Traceability/FProduct/FProductForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MyButton = styled(Button)({
  height: 36,
  //padding: '0px',
});

const useStyles = makeStyles({
  root: {
    //color: 'white',
    height: 36,
    padding: '10px',
    //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
});

export default function ApprovedInformationTable(props) {
  const utils = new TraceabilityUtils();
  const classes = React.useContext(StylesContext);
  const classesLocal = useStyles();


  const EMPTY_MESSAGE = {
    open: false,
    message: '',
    status: 'info',
  };

  const tabStatuses = {
    counted: false,
    column: 'type',
    tabs: [
      { title: 'Suppliers', value: 'Supplier' },
      { title: 'Customers', value: 'Customer' },
      { title: 'Formulations', value: 'Formulation' },
    ]
  }

  const indexedColumns = {

    Supplier: [
      { title: "Supplier Name", field: "name" },
      { title: "Supplier ID", field: "supplier_id" },
      { title: "Email", field: "email" },
      { title: "Phone Number", field: "phone_number" },
    ],
    Customer: [
      { title: "Customer Name", field: "name" },
      { title: "Customer ID", field: "customer_id" },
      { title: "Company", field: "company" },
      { title: "Email", field: "email" },
      { title: "Phone Number", field: "phone_number" },
    ],
    Formulation: [
      { title: "Formulation Name", field: "name" },
      { title: "Batch Yield", field: "batch_yield" },
    ],
    // Product: [
    //   { title: "Product Name", field: "name" },
    //   { title: "Product Description", field: "description" },
    // ],
  }

  const [loading, setLoading] = useState(true);
  const [recordSelected, setRecordSelected] = useState(false);
  const [shouldClose, setShouldClose] = useState(true);
  const [message, setMessage] = useState({ ...EMPTY_MESSAGE });
  const [user, setUser] = useState(null);
  const [customers, setCustomers] = useState(false);
  const [suppliers, setSuppliers] = useState(false);
  const [formulations, setFormulations] = useState(false);
  const [consolidatedRecords, setConsolidatedRecords] = useState(false);
  const [inventoryLocations, setInventoryLocations] = useState(false);
  const [ingredients, setIngredients] = useState(false);
  const [products, setProducts] = useState(false);
  const [units, setUnits] = useState(false);
  const [dialogueOpen, setDialogueOpen] = useState(false);
  const [tabValue, setTabValue] = useState('Supplier');
  const [dummyKey, setDummyKey] = useState(0);
  const [customerIdsDict, setCustomerIdsDict] = useState({});
  const [supplierIdsDict, setSupplierIdsDict] = useState({});

  const createCustomerIdsDict = (customerData) => {
    let dict = {};
    customerData.forEach(customer => {
      dict[customer.id] = customer.customer_id;
    });
    setCustomerIdsDict(dict);
  };

  const createSupplierIdsDict = (supplierData) => {
    let dict = {};
    supplierData.forEach(supplier => {
      dict[supplier.id] = supplier.supplier_id;
    });
    setSupplierIdsDict(dict);
  };

  useEffect(() => {
    if (customers && suppliers && formulations && units && inventoryLocations && !consolidatedRecords) {
      let newConsolidatedRecords = [];

      newConsolidatedRecords = newConsolidatedRecords.concat(customers.map((customer) => {
        return { ...customer, type: 'Customer' };
      }))

      newConsolidatedRecords = newConsolidatedRecords.concat(suppliers.map((supplier) => {
        return { ...supplier, type: 'Supplier' };
      }))

      // newConsolidatedRecords = newConsolidatedRecords.concat(products.map((product) => {
      //   return { ...product, type: 'Product' };
      // }))

      if (units?.length && formulations?.length)
        newConsolidatedRecords = newConsolidatedRecords.concat(formulations.map((formulation) => {

          return {
            ...formulation,
            type: 'Formulation',
            batch_yield: formulation.unit_value + ' ' + units.filter(unit => unit.pk === formulation.unit_type).map(unit => unit.abbreviation).toString()
          };
        }))

      newConsolidatedRecords = newConsolidatedRecords.concat(inventoryLocations.map((inventoryLocation) => {

        return { ...inventoryLocation, type: 'Location' };
      }))

      setConsolidatedRecords([...newConsolidatedRecords.filter(record => !record.soft_delete)]);
    }
  }, [customers, suppliers, formulations, units, inventoryLocations])

  useEffect(() => {
    const authApi = new API().getAuthAPI();
    const customerApi = new Traceability().getFCustomerAPI();
    // const supplierApi = new Traceability().getSupplierAPI();
    const supplierApi = new Traceability().getFSuppliersAPI();
    // const productsApi = new Traceability().getFProductsAPI();
    const companyInfoApi = new Traceability().getCompanyInfoAPI();
    const formulaApi = new Traceability().getFFormulationsAPI();
    const unitApi = new Traceability().getUnitsAPI();
    const ingredientApi = new Traceability().getFIngredientsAPI();
    const inventoryLocationApi = new Traceability().getInventoryLocationAPI();

    if (props.user) {
      setUser(props.user)
    }
    else {
      authApi.getAuthedProfile().then(e => {
        setUser(e.data);
      }).catch(e => {
        //console.log(e);
      })
    }

    if (props.inventoryLocations) {
      setInventoryLocations(props.inventoryLocations);
    }
    else {
      inventoryLocationApi.listInventoryLocations().then(e => {
        setInventoryLocations(e.data);
      }).catch(e => {
        //console.log(e);
      })
    }

    if (props.customers) {
      setCustomers(props.customers);
    }
    else {
      customerApi.listCustomer().then(e => {
        setCustomers(e.data);
        createCustomerIdsDict(e.data);
      }).catch(e => {
        //console.log(e);
      })
    }

    if (props.suppliers) {
      setSuppliers(props.suppliers);
    }
    else {
      supplierApi.listFSuppliers().then(e => {
        setSuppliers(e.data);
        createSupplierIdsDict(e.data);
      }).catch(e => {
        // console.log(e);
      })
    }

    // if (props.products) {
    //   setProducts(props.products);
    // }
    // else {
    //   productsApi.listFProducts().then(e => {
    //     setProducts(e.data);
    //   }).catch(e => {
    //     // console.log(e);
    //   })
    // }

    if (props.formulations) {
      setFormulations(props.formulations)
    }
    else {
      formulaApi.listFFormulations().then(e => {
        setFormulations(e.data);
      }).catch(e => {
        //console.log(e);
      })
    }

    if (props.units) {
      setUnits(props.units);
    }
    else {
      //unitApi.listGlobalUnits().then(e => {
      unitApi.listUnits().then(e => {
        setUnits(e.data);
      }).catch(e => {
        //console.log(e);
      })
    }

    if (props.ingredients) {
      setIngredients(props.ingredients)
    }
    else {
      ingredientApi.listFIngredients().then(e => {
        setIngredients(e.data);
      }).catch(e => {
        //console.log(e);
      })
    }

    setLoading(false);

  }, []);


  function onDelete(response, currentRecord) {
    let newConsolidatedRecords = [...consolidatedRecords];

    const recordsId = currentRecord.pk ?? currentRecord.id;

    let recordIndex = newConsolidatedRecords.findIndex((record) => {

      return (record.type == currentRecord.type && (record.pk == recordsId || record.id == recordsId));
    });

    newConsolidatedRecords.splice(recordIndex, 1);
    setConsolidatedRecords([...newConsolidatedRecords.filter(record => !record.soft_delete)]);
    setRecordSelected(false);
  }


  /**
   * Handles keeping the list up to date with the back end, including updating all lines.
   * 
   * @param {JSON} response - a response object from the server
   */
  function onSave(response, currentRecord, saveAndContinue, ingredient) {

    let newConsolidatedRecords = [...consolidatedRecords];

    const recordsId = response.data.pk ?? response.data.id;
    let recordIndex = newConsolidatedRecords.findIndex((record) => {

      return (record.type == currentRecord.type && (record.pk == recordsId || record.id == recordsId));
    });
    // const ingredientApi = new Traceability().getFIngredientsAPI();
    // ingredientApi.listFIngredients().then(e => {
    //   setIngredients(e.data);
    // }).catch(e => {
    //   //console.log(e);
    // })
    const inventoryLocationApi = new Traceability().getInventoryLocationAPI();
    inventoryLocationApi.listInventoryLocations().then(e => {
      setInventoryLocations(e.data);
    }).catch(e => {
      //console.log(e);
    })

    const unitApi = new Traceability().getUnitsAPI();
    unitApi.listUnits().then(e => {
      setUnits(e.data);
    }).catch(e => {
      //console.log(e);
    })

    //New Record
    if (recordIndex == -1) {
      if (currentRecord.type != 'Formulation' && tabValue != 'Formulation') {
        newConsolidatedRecords.unshift({ ...response.data, type: currentRecord.type ? currentRecord.type : tabValue });
        setConsolidatedRecords([...newConsolidatedRecords]);
        setRecordSelected({ ...response.data, type: currentRecord.type ? currentRecord.type : tabValue });
      } else {
        formulaOnSave(response, newConsolidatedRecords, currentRecord, ingredient);
      }
    }
    else {
      if (currentRecord.type != 'Formulation' && tabValue != 'Formulation') {
        newConsolidatedRecords.splice(recordIndex, 1, { ...response.data, type: currentRecord.type ? currentRecord.type : tabValue });
        setConsolidatedRecords([...newConsolidatedRecords]);
        setRecordSelected({ ...response.data, type: currentRecord.type ? currentRecord.type : tabValue });
      }
      else {
        formulaOnSave(response, newConsolidatedRecords, currentRecord, ingredient);
      }
    }
    if (saveAndContinue) {
      cancel();
      setMessage({ open: true, message: 'Saved Successfully', status: 'success' });
    }
  }

  function formulaOnSave(response, consolidatedRecords, currentRecord, ingredient) {
    const formulaApi = new Traceability().getFFormulationsAPI();
    let newConsolidatedRecords = [...consolidatedRecords];
    console.log("response.data >> ",response.data);

    formulaApi.listFFormulations(response.data.id).then(subResponse => {

      // const ingredientApi = new Traceability().getFIngredientsAPI();
      // ingredientApi.listFIngredients().then(e => {
      //   setIngredients(e.data);
      // }).catch(e => {
      //   //console.log(e);
      // })

      let recordIndex = newConsolidatedRecords.findIndex((record) => {
        return (record.type == currentRecord.type && record.id == response.data.id);
      });

      if (recordIndex == -1) {
        newConsolidatedRecords.unshift({
          ...subResponse.data,
          type: currentRecord.type ? currentRecord.type : tabValue,
          ingredient: ingredient,
          batch_yield: currentRecord.unit_value + ' ' + units.filter(unit => unit.pk === currentRecord.unit_type).map(unit => unit.abbreviation).toString()

        });
        setConsolidatedRecords([...newConsolidatedRecords]);
      }
      else {
        newConsolidatedRecords.splice(recordIndex, 1, {
          ...subResponse.data,
          type: currentRecord.type ? currentRecord.type : tabValue,
          ingredient: ingredient,
          batch_yield: currentRecord.unit_value + ' ' + units.filter(unit => unit.pk === currentRecord.unit_type).map(unit => unit.abbreviation).toString()

        });
        setConsolidatedRecords([...newConsolidatedRecords]);
      }

      setRecordSelected({
        ...subResponse.data,
        type: currentRecord.type ? currentRecord.type : tabValue
      });

      setConsolidatedRecords([...newConsolidatedRecords.filter(record => !record.soft_delete)]);
      setMessage({
        open: true,
        message: 'Saved Successfully',
        status: 'success',
      });


    }).catch(error => {
      //console.log('Error: ' + error.message);
      setMessage({
        open: true,
        message: 'Could not sync with server. Please refresh page.',
        status: 'error',
      });
    })
  }
 
  function openRecordManager(event, rowData) {
    //let newIndex = utils.matchOnPk(consolidatedRecords, rowData.pk, '_index_');

    setRecordSelected({ ...rowData })
    setDialogueOpen(true);

  }

  function createNewRecord(event) {
    setRecordSelected({});
    setDialogueOpen(true);

  }

  function closeModal() {
    if (!shouldClose) {
      if (!window.confirm("Are you sure you want to close without saving?")) {
        return;
      }
    }

    cancel();
  }

  function cancel() {
    setRecordSelected(false);
    setDialogueOpen(false);
  }
  
  return (
    <>
      {loading && (!customers || !suppliers || !products) &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }
      {(!loading && consolidatedRecords) &&
        <Paper elevation={0} square className={classes.generalListPaperContainer} >
          <Grid container spacing={0} style={{ padding: '0px' }}>
            <Grid item xs={12}>
              <TabbableGroupedTable
                tabStatuses={tabStatuses}
                columns={indexedColumns[tabValue]}

                data={consolidatedRecords.filter(record => {
                  return !record.soft_delete;
                })}

                grabTabValue={setTabValue}
                options={{
                  pageSize: 10,
                }}
                title={tabValue}
                localization={{
                  body: {
                    emptyDataSourceMessage: 'Click New Order to create a new order.',
                  }
                }}

                actions={
                  [
                    {
                      icon: (props) => (
                        <Button size="small" color="primary" variant="outlined" aria-label="add"
                          className={classes.tabTableFreeAction}
                        >
                          {'New ' + tabValue}
                        </Button>),
                      tooltip: 'New Record',
                      isFreeAction: true,
                      onClick: (event) => { createNewRecord(event) }
                    },
                    {
                      icon: (props) => (
                        <Button size="small" color="primary" variant="outlined" aria-label="add">
                          {'Edit'}
                        </Button>
                      ),
                      tooltip: 'Open Manager',
                      onClick: (event, rowData) => openRecordManager(event, rowData)
                    },
                  ]
                }

                onRowClick={(event, rowData) => openRecordManager(event, rowData)}
              />
            </Grid>
          </Grid>

          <Message
            open={message.open}
            message={message.message}
            severity={message.status}
            vertical="bottom"
            horizontal="left"
            handleClose={() => { setMessage({ ...message, open: false }); setLoading(false) }}
          />
        </Paper>
      }


      <Dialog fullScreen open={dialogueOpen} onClose={closeModal} TransitionComponent={Transition}>
        <DialogContent className={classes.generalFormDialogueContainer}>
          {(!loading && consolidatedRecords && recordSelected && (tabValue == 'Customer')) &&
            <CustomerForm
              customer={recordSelected}
              setShouldClose={setShouldClose}
              closeModal={closeModal}
              cancel={cancel}
              onSave={onSave}
              onDelete={onDelete}
              user={user}
              customerIdsDict={customerIdsDict}
              setCustomerIdsDict={setCustomerIdsDict}
              activeTraceability={props.activeTraceability}
            />
          }

          {(!loading && consolidatedRecords && recordSelected && (tabValue == 'Supplier')) &&
            <SupplierForm
              supplier={recordSelected}
              setShouldClose={setShouldClose}
              closeModal={closeModal}
              cancel={cancel}
              onSave={onSave}
              onDelete={onDelete}
              user={user}
              supplierIdsDict={supplierIdsDict}
              setSupplierIdsDict={setSupplierIdsDict}
              activeTraceability={props.activeTraceability}
            />
          }

          {(!loading && consolidatedRecords && recordSelected && (tabValue == 'Product')) &&
            <FProductForm
              product={recordSelected}
              setShouldClose={setShouldClose}
              closeModal={closeModal}
              cancel={cancel}
              onSave={onSave}
              onDelete={onDelete}
              user={user}
              activeTraceability={props.activeTraceability}
            />
          }

          {(!loading && consolidatedRecords && recordSelected && (tabValue == 'SKU')) &&
            <SKUForm
              onSave={onSave}
              onDelete={onDelete}
              sku={recordSelected}
              setShouldClose={setShouldClose}
              closeModal={closeModal}
              cancel={cancel}
              user={user}
              units={units}
              formulations={formulations}
              ingredients={ingredients}
              suppliers={suppliers}
              inventoryLocations={inventoryLocations.filter(invLoc => invLoc.is_production_location)}
              activeTraceability={props.activeTraceability}
            />
          }

          {(!loading && consolidatedRecords && recordSelected && (tabValue == 'Formulation')) &&
            <FormulaSKUForm
              key={dummyKey}
              onSave={onSave}
              onDelete={onDelete}
              formulation={recordSelected}
              // sku={recordSelected.sku ? recordSelected.sku : {}}
              setShouldClose={setShouldClose}
              closeModal={closeModal}
              cancel={cancel}
              user={user}
              units={units}
              ingredients={ingredients}
              setIngredients={setIngredients}
              inventoryLocations={inventoryLocations.filter(invLoc => invLoc.is_production_location)}
              activeTraceability={props.activeTraceability}
              //setIsBlocking={() => { console.log('replace with setShouldClose') }}
              suppliers={suppliers}
            />
          }

          {(!loading && consolidatedRecords && recordSelected && (tabValue == 'Location')) &&
            <InventoryLocationForm
              //handleSubmit={handleSubmit}
              inventoryLocation={recordSelected}
              setShouldClose={setShouldClose}
              cancel={cancel}
              user={user}
              onSave={onSave}
              onDelete={onDelete}
              closeModal={closeModal}
              activeTraceability={props.activeTraceability}
            />
          }

        </DialogContent>
      </Dialog>

    </>
  )
}

ApprovedInformationTable.propTypes = {
  user: PropTypes.object,
  formulations: PropTypes.arrayOf(PropTypes.object),
  ingredients: PropTypes.arrayOf(PropTypes.object),
  customers: PropTypes.arrayOf(PropTypes.object),
  suppliers: PropTypes.arrayOf(PropTypes.object),
  products: PropTypes.arrayOf(PropTypes.object),
  // skus: PropTypes.arrayOf(PropTypes.object),
  units: PropTypes.objectOf(PropTypes.object),
  inventoryLocations: PropTypes.objectOf(PropTypes.object),
  companyInfo: PropTypes.objectOf(PropTypes.object),
  activeTraceability: PropTypes.object,
}