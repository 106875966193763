import React, { useState, useEffect } from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import API from "../../Api/Api";
import Message from "../../Components/Message";
import DeleteIcon from '@material-ui/icons/Delete';
import FormulationFields from "./FormulationFields";
import TraceabilityUtils from "../TraceabilityUtils";
import PropTypes from "prop-types";
import { StylesContext } from "../../App";

import Card from "@material-ui/core/Card";

import BulkLogo from './weight.svg';
import PrepackagedLogo from './inventory_2_black_24dp.svg';

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Traceability from 'Traceability/Traceability';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      border: '1px solid #18BFF6',
    }
  }
});

export default function FormulaSKUForm(props) {
  const classes = React.useContext(StylesContext);
  const localClasses = useStyles();
  const utils = new TraceabilityUtils();
  // const [sku, setSku] = useState(props.sku);
  const [formulation, setFormulation] = useState(props.formulation);
  const [message, setMessage] = useState(utils.emptyMessage());
  const [lockUpdate, setLockUpdate] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    let newFormulation = { ...props.formulation };
    if (newFormulation.ingredients) {
      newFormulation.ingredients = props.formulation.ingredients.map((ingInstance) => {
        if (typeof (ingInstance.unit_type) != 'object') {
          ingInstance.unit_type = props.units.find(unit => unit.pk == ingInstance.unit_type);
        }
        return ingInstance;
      });
    }

    setFormulation(props.formulation);

  }, [props.formulation])

  // useEffect(() => {
  //   //Creating a new record causes the screen to freak out while the system sorts out all the data returning from the BE,
  //   //So lock the parent from updating the form until it's sorted and has all the data it needs.
  //   if(lockUpdate && (Object.keys(props.sku).length == 0) ) {
  //     return;
  //   }
  //   //sku should get split at parent level, capture changes here.
  //   setSku(props.sku);

  // }, [props.sku])

  function setIngredientInstances(newIngredientInstances) {
    setFormulation({ ...formulation, ingredients: newIngredientInstances });
  }

  function handleChange(event) {
    props.setShouldClose(true);
    //if (props.setBlockNavigation) props.setBlockNavigation(true);
    const value = event.target.value;
    setFormulation({ ...props.formulation, [event.target.name]: value });
  }

  // function validateSku() {
  //   if(!sku.sku_type) {
  //     setMessage({ open: true, message: 'SKUS require a type.', status: 'error' });
  //     return false;
  //   }

  //   if (!sku.name) {
  //     setMessage({ open: true, message: 'Please enter a SKU name.', status: 'error' });
  //     return false;
  //   }

  //   if (!sku.sku_id) {
  //     setMessage({ open: true, message: 'Please enter a SKU ID.', status: 'error' });
  //     return false;
  //   }

  //   if (!sku.unit_type && !sku.unit_type_placeholder) {
  //     setMessage({ open: true, message: 'Please choose a SKU Unit Type.', status: 'error' });
  //     return false;
  //   }

  //   if(sku.sku_type == 'BULK') {
  //     //nothing for this yet.
  //   }

  //   if(sku.sku_type == 'PREP') {
  //     if (!sku.unit_value) {
  //       setMessage({ open: true, message: 'Please enter a value for a single unit of this SKU.', status: 'error' });
  //       return false;
  //     }

  //     if (!sku.single_unit_type) {
  //       setMessage({ open: true, message: 'Please choose a Unit Type. for a single unit of this SKU.', status: 'error' });
  //       return false;
  //     }

  //     if (sku.pallet_unit_value && !sku.case_unit_value) {
  //       setMessage({ open: true, message: 'Must specify units per case if specifying case per pallet.', status: 'error' });
  //       return false;
  //     }
  //   }

  //   return true;
  // }

  function validateForm() {
    setSubmitAttempted(true);

    if (!formulation.name || !(/^[A-Za-z][A-Za-z\s]*$/).test(formulation.name)) {
      setMessage({ open: true, message: 'Please enter a formulation name.', status: 'error' });
      return false;
    }

    if (!formulation.unit_value || isNaN(formulation.unit_value) || formulation.unit_value.trim() === '') {
      setMessage({ open: true, message: 'Please enter the valid formulation yield.', status: 'error' });
      return false;
    }

    if (!formulation.unit_type) {
      setMessage({ open: true, message: 'Please enter the unit type of the formulation yield.', status: 'error' });
      return false;
    }

    if (formulation.ingredients == null || formulation.ingredients.length == 0) {
      setMessage({ open: true, message: 'Please enter at least one ingredient.', status: 'error' });
      return false;
    }

    var validIngs = true;
    formulation.ingredients.filter(skuInstance => !skuInstance.delete).forEach((ingLine) => {
      if (!ingLine.ingredient_name || !ingLine.unit_value || !ingLine.unit_type) {
        validIngs = false;
      }
    })

    if (!validIngs) {
      return false;
    }
    return true;
    // return validateSku()
  }

  function handleSubmit(saveAndContinue) {
    setSubmitting(true);
    console.log("here");
    if (!validateForm()) {
      console.log("here2");
      setSubmitting(false);
      return;
    }

    /*let unitSelectedPks = unitsSelected.map((unit) => {
      return unit.pk;
    })*/
    let shouldSubmit = true;

    let formulaPayload = {};
    formulaPayload.name = formulation.name;
    formulaPayload.description = formulation.description;
    formulaPayload.unit_type = formulation.unit_type;
    formulaPayload.unit_value = formulation.unit_value;

    formulaPayload.ingredients = formulation.ingredients.map(ingredient => {
      // Check if ingredient.id is already available
      if (ingredient.id) {
        return {
          "ingredient": ingredient.id,
          "unit_value": ingredient.unit_value,
          "unit_type": ingredient.unit_type.pk
        };
      } else {
        // Find the corresponding ingredient from props.ingredients
        const foundIngredient = props.ingredients.find(propIngredient => propIngredient.name === ingredient.ingredient_name);
        return {
          "ingredient": foundIngredient ? foundIngredient.id : null,
          "unit_value": ingredient.unit_value,
          "unit_type": ingredient.unit_type.pk
        };
      }
    });
    

    //console.log('asd sku: ' + JSON.stringify(sku));

    // formulaPayload.ingredients = formulation.ingredients.filter(skuInstance => !skuInstance.delete);

    // formulaPayload.ingredients.forEach((ingredientInstance) => {
    //   const relatedIngredient = props.ingredients.find((ingredient) => ingredient.name == ingredientInstance.ingredient_name);

    //   if(!relatedIngredient && shouldSubmit) {
    //     shouldSubmit = window.confirm('This will add the ingredient ' + ingredientInstance.ingredient_name + ' to the system. Continue?');
    //   }
    // });

    if (!shouldSubmit) {
      setSubmitting(false);
      setMessage({
        open: true,
        message: 'Save not attempted.',
        status: 'error',
      });
      return;
    }

    // let organization = JSON.parse(localStorage.getItem("organization"));
    // formulaPayload.organization = organization.pk;
    // formulaPayload.traceability = props.activeTraceability.pk;

    // formulaPayload.ingredients = formulaPayload.ingredients.map((ingInstance) => {
    //   return {...ingInstance, unit_type: ingInstance.unit_type.pk};
    // })

    const api = new Traceability().getFFormulationsAPI();

    setLockUpdate(true);
    if (formulation.id) {
      // delete formulaPayload.sku.formulation;

      formulaPayload.id = formulation.id;
      console.log("Update formulation data = ", formulaPayload);

      api.updateFFormulations(formulaPayload).then(response => {
        setSubmitAttempted(false);
        if (props.setShouldClose) {
          props.setShouldClose(true);
        }

        let newFormulation = { ...response.data };
        setFormulation(newFormulation);

        // setSku({...newFormulation.sku, sku_type: sku.sku_type});

        setMessage({
          open: true,
          message: 'Saved Successfully',
          status: 'success',
        });
        if (props.onSave) {
          props.onSave(response, { ...response.data, type: 'Formulation' }, saveAndContinue);
        }
        setLockUpdate(false);
        setSubmitting(false);
      }).catch(error => {
        setSubmitting(false);
        //console.log('Error Response: ' + JSON.stringify(error));
        setMessage({ open: true, message: JSON.stringify(error.response.data).replace('{', '').replace('}', ''), status: 'error' });
      });
    }
    else {

      console.log("create formulation data = ", formulaPayload);

      api.createFFormulations(formulaPayload).then(response => {
        setSubmitAttempted(false);
        if (props.setShouldClose) {
          props.setShouldClose(true);
        }
        let newFormulation = { ...response.data };

        setMessage({
          open: true,
          message: 'Saved Successfully',
          status: 'success',
        });
        if (props.onSave) {
          props.onSave(response, { ...response.data, type: 'Formulation' }, saveAndContinue);
        }
        setSubmitting(false);
      }).catch(error => {
        setSubmitting(false);
        //console.log('Error Response: ' + JSON.stringify(error));
        setMessage({ open: true, message: JSON.stringify(error.response.data).replace('{', '').replace('}', ''), status: 'error' });
      });
    }
  }

  //todo join this to the sku as well, in the BE through triggers
  function handleDelete() {
    let formulationName = formulation.name || 'this formulation';

    if (!window.confirm('Are you sure you want to delete ' + formulationName + '?')) {
      return;
    }

    if (!formulation || !formulation.id) {
      props.closeModal()
    }

    const formAPI = new Traceability().getFFormulationsAPI();

    formAPI.deleteFFormulations(formulation.id).then(response => {

      if (props.onDelete) {
        props.onDelete(response, { id: formulation.id, type: 'Formulation', soft_delete: true });
      }
      setMessage({ open: true, message: 'Deleted Successfully', status: 'success' });
      props.closeModal();
    }).catch(error => {
      setMessage({ open: true, message: 'Server Error: ' + utils.formatError(error), status: 'error' });
      //console.log('Error Response: ' + JSON.stringify(error));
    });
  }

  return (
    <>
      {/* { (!sku.sku_type || sku.sku_type == '') &&
        <Grid container alignItems="center" justify="center" style={{width: '100%', height: '75%'}}>
          <Grid item xs={12}>
            <Grid item container xs={12} alignItems="center" justify="center" style={{paddingBottom: '36px'}}>
              <Typography style={{fontWeight: 500, fontSize: '34px', textAlign: 'center', cursor: 'default'}}>
                Choose a type for your new formulation
              </Typography>
            </Grid>
            <Grid item container xs={12} alignItems="center" justify="center">
              <Card elevation={1} style={{width: '260px', height: '260px', margin: '8px'}} classes={{root: localClasses.root}} onClick={() => { setSku({ ...sku, sku_type: 'PREP' }) }}>
                <Grid container alignItems="center" justify="center"  style={{height: '100%'}}>
                  <Grid item container justify="center" alignItems="center" xs={12}>
                    <Grid item container justify="center" alignItems="center" xs={12} style={{paddingBottom: '26px'}}>
                      <img src={PrepackagedLogo} width="60" height="60"></img>
                    </Grid>
                    <Grid item container xs={12} alignItems="center" justify="center" style={{padding: '0px 8px 8px 8px'}}>
                      <Typography style={{fontWeight: 700, fontSize: '16px', textAlign: 'center', cursor: 'default'}}>PRE-PACKAGED</Typography>
                    </Grid>
                    <Grid item container xs={12} alignItems="center" justify="center" style={{maxWidth: '175px'}}>
                      <Typography style={{opacity: 0.50, textAlign: 'center', fontSize: '12px', fontWeight: 400, cursor: 'default'}}>
                        Pre-packaged formulations produce units or cases
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Card> 
              <Card elevation={1} style={{width: '260px', height: '260px', margin: '8px'}} classes={{root: localClasses.root}} onClick={() => { setSku({ ...sku, sku_type: 'BULK' }) }}>
                <Grid container alignItems="center" justify="center" style={{height: '100%'}}>
                  <Grid item container xs={12} justify="center" alignItems="center" xs={12} >
                    <Grid item container justify="center" alignItems="center" xs={12} style={{paddingBottom: '26px'}}>
                      <img src={BulkLogo} width="60" height="60"></img>
                    </Grid>
                    <Grid item container xs={12} alignItems="center" justify="center" style={{padding: '0px 8px 8px 8px'}}>
                      <Typography style={{fontWeight: 700, fontSize: '16px', textAlign: 'center', cursor: 'default'}}>BULK</Typography>
                    </Grid>
                    <Grid item container xs={12} alignItems="center" justify="center">
                      <Typography style={{opacity: 0.50, textAlign: 'center', fontSize: '12px', fontWeight: 400, maxWidth: '175px', cursor: 'default'}} >
                        Bulk formulations produce in weight/volume and are used in other formulations
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid> 
              </Card>
            </Grid>
          </Grid>
        </Grid>
      } */}

      {/* { sku.sku_type && sku.sku_type != '' && */}
      {/* Header Section */}
      <>
        <Grid container spacing={3} className={classes.generalContainerGridHead}>
          <Grid item xs={11}>
            <Typography variant="h6" noWrap className={classes.generalFormHeaderTypography}>
              {props.formulation.id ? "Edit Formulation" : "Add Formulation"}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton style={{ float: 'right' }} onClick={props.closeModal}>
              <CancelIcon style={{ float: 'right' }} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container spacing={3} className={classes.generalContainerGridBody}>

          <Grid item container xs={12} alignItems="center" justify="center">
            <FormulationFields
              user={props.user}
              formulation={formulation}
              setFormulation={setFormulation}
              units={props.units}
              ingredients={props.ingredients}
              setIngredients={props.setIngredients}
              inventoryLocations={props.inventoryLocations}
              setIngredientInstances={setIngredientInstances}
              setShouldClose={props.setShouldClose}

              // sku={sku}
              // setSku={setSku}
              suppliers={props.suppliers}
              submitAttempted={submitAttempted}
            />
          </Grid>

        </Grid>
        {/* This Grid serves as the footer element. */}
        <Paper elevation={0} square className={classes.generalFormPaperStickyFooter}>
          <Grid container spacing={3} className={classes.generalContainerGridFoot}>
            <Grid item container xs={12} alignItems="center" justify="flex-end">
              {formulation.id &&
                <Button
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  style={{ marginLeft: "8px" }}
                  onClick={() => { handleDelete() }}
                  disabled={submitting}
                >
                  Delete
                </Button>
              }
              {props.closeModal && <>
                <Button
                  //variant="outlined"
                  color="secondary"
                  style={{ marginLeft: "8px" }}
                  onClick={props.closeModal}
                  disabled={submitting}
                >
                  Cancel
                </Button>
              </>}
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "8px" }}
                onClick={() => { handleSubmit(false) }}
                disabled={submitting}
              >
                Save Changes
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "8px" }}
                onClick={() => { handleSubmit(true) }}
                disabled={submitting}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <Message
          open={message.open}
          message={message.message}
          severity={message.status}
          vertical="bottom"
          horizontal="left"
          handleClose={() => { setMessage({ ...message, open: false }) }}
        />
      </>
      {/* } */}
    </>
  )
}



FormulaSKUForm.propTypes = {
  // sku: PropTypes.object,
  units: PropTypes.objectOf(PropTypes.object),
  formulation: PropTypes.object,
  ingredients: PropTypes.arrayOf(PropTypes.object),
  suppliers: PropTypes.arrayOf(PropTypes.object),
  inventoryLocations: PropTypes.arrayOf(PropTypes.object),
  handleSubmit: PropTypes.func,
  user: PropTypes.object.isRequired,
  activeTraceability: PropTypes.object.isRequired,
  setShouldClose: PropTypes.func,
  onSave: PropTypes.func,
  closeModal: PropTypes.func,
  cancel: PropTypes.func,
}