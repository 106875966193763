import axios from 'axios';

import OrganizationAPI from "./OrganizationAPI";
import OrganizationInviteAPI from "./OrganizationInviteAPI"
import HaccpPlanAPI from "./HaccpPlanAPI";
import FormulationsAPI from "./FormulationsAPI";
import IngredientsAPI from "./IngredientsAPI";
import ProcessAPI from "./ProcessAPI";
import CrossContaminationPointsAPI from "./CrossContaminationPointsAPI";
import CcpAPI from "./CcpAPI";
import ProductDescriptionAPI from "./ProductDescriptionAPI";
import Auth from "./Auth";
import HazardAPI from "./HazardAPI";
import FormulationTemplateAPI from "./FormulationTemplateAPI";
import IngredientTemplateAPI from "./IngredientTemplateAPI";
import HazardTemplateAPI from "./HazardTemplateAPI";
import CCPTemplateAPI from "./CcpTemplateAPI";
import ProcessTemplateAPI from "./ProcessTemplateAPI";
import ProductDescriptionTemplateAPI from "./ProductDescriptionTemplateAPI";
import HaccpPlanTemplateAPI from "./HaccpPlanTemplateAPI";
import CcpDeterminationWorksheetAPI from "./CcpDeterminationWorksheetAPI";
import TrafficFlowDiagramAPI from "./TrafficFlowDiagramAPI";
import TrafficFlowDiagramTemplateAPI from "./TrafficFlowDiagramTemplateAPI";
import ProcessFlowDiagramAPI from "./ProcessFlowDiagramAPI";
import CrossContaminationPointTemplateAPI from "./CrossContaminationPointTemplateAPI";
import ProcessFlowDiagramTemplateAPI from "./ProcessFlowTemplateAPI";

import IngredientDatasetAPI from "./IngredientDatasetAPI";
import ProcessDatasetAPI from "./ProcessDatasetAPI";
import ChangelogAPI from "./ChangelogAPI";
import ImagesAPI from "./ImagesAPI";
import HazardDatasetAPI from "./HazardDatasetAPI";
import DeviationAPI from './DeviationAPI';
import DeviationImagesAPI from './DeviationImagesAPI';
import CameraImageAPI from "./CameraImageAPI";
import FormAPI from "./FormAPI";
import PlanBuilderAPI from "./PlanBuilderAPI";
import FacilityAPI from "./FacilityAPI";
import AdminToolAPI from "./AdminToolAPI"
import PackagingAPI from "./PackagingAPI";
import SignatureAPI from "./SignatureAPI";
import TaskAPI from "./TaskAPI";
import EventAPI from "./EventAPI";
import LogsAPI from "./LogsAPI";
import MonitoringAPI from "./MonitoringAPI";
import OnboardingAPI from "./OnboardingAPI";
import PolicyAPI from './PolicyAPI';
import CustomFieldsAPI from "./CustomFormFields/api";

export const LOCALHOST = false;
export const DEPLOY_TEST = true;
export const DEPLOY_PRODUCTION = false;

export let URL;

if (LOCALHOST) {
  URL = 'http://127.0.0.1:9000/';
}


else if (DEPLOY_TEST) {
  // URL = 'https://foodbyte-backend-test.herokuapp.com/';
  URL = 'https://dev.api.foodbyte.io';
}

else if (DEPLOY_PRODUCTION) {
  URL = 'https://api-foodbyte.herokuapp.com/';
}

export const API_VERSION = 'v1';

let refreshingCall = null; // stores the most recent token refresh request, so that it can be chained with requests that occur while this request is pending

export function getItemFromLocalStorage(key) {
  if (!key) {
    return null;
  }

  let str = localStorage.getItem(key);
  if (str) {
    return str;
  }

  return null;
}

class API {
  constructor() {
    this.initializeAPI();
    this.version = API_VERSION;
  }

  initializeAPI() {
    this.api = axios.create({
      baseURL: URL,
    });

    if (this.api) {
      this.setInterceptors();
    }
  }

  createErrorReport(reportData) {
    /**
     * Submits an error report to the server
     * 
     * reportData = { 
     *  path: <str>,
     *  request: <obj, any format>,
     * }
     * */
    return this.api.post('/v1/error_reports/', reportData);
  }

  getAppVersion() {
    return this.api.get('app_version/');
  }

  setInterceptors() {
    // Add a request interceptor
    this.api.interceptors.request.use(function (config) {

      // Do something before request is sent
      let access_token = getItemFromLocalStorage('access_token')
      let organization = getItemFromLocalStorage('organization');

      if (organization) {
        organization = JSON.parse(organization);
      }

      if (config.url.includes("reset_password_request") || config.url.includes("change_password")|| config.url.includes("user_otp")) {
        return config;
      }

      if (access_token) {
        config.headers['Authorization'] = `Bearer ${access_token}`;
        config.headers['Organization'] = `${organization ? organization.pk : null}`;
      }
      else {
        config.headers['Authorization'] = null;
        config.headers['Organization'] = null;
      }

      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });

    // Add a response interceptor
    this.api.interceptors.response.use((response)=> {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    }, (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.config.url === "/api/token/refresh/") {
        window.location.replace('/login');
        localStorage.clear();
      }

      if (error.config.url !="/api/token/" && (error.response.status === 401 || error.response.status === 403)) { // try refreshing the token, and chain the call after the last refresh call 
       
        if (refreshingCall) {
          return refreshingCall.then(() => {
            return this.api.request(error.config);
          })
        }

        refreshingCall = this.getAuthAPI().refresh().then(() => {
          refreshingCall = null;
          return this.api.request(error.config);
        });

        return refreshingCall;
      }

      return Promise.reject(error);
    });
  }

  getPlanBuilderAPI() {
    return new PlanBuilderAPI(this.api, API_VERSION);
  }

  getChangelogAPI() {
    return new ChangelogAPI(this.api, API_VERSION);
  }

  getImagesAPI() {
    return new ImagesAPI(this.api, API_VERSION);
  }

  getAuthAPI() {
    return new Auth(this.api, API_VERSION);
  }

  getOrganizationAPI() {
    return new OrganizationAPI(this.api, API_VERSION);
  }

  getOrganizationInviteAPI() {
    return new OrganizationInviteAPI(this.api, API_VERSION)
  }

  getHaccpPlanAPI() {
    return new HaccpPlanAPI(this.api, API_VERSION);
  }

  getHaccpPlanTemplateAPI() {
    return new HaccpPlanTemplateAPI(this.api, API_VERSION);
  }

  getFormulationTemplateAPI() {
    return new FormulationTemplateAPI(this.api, API_VERSION);
  }

  getFormulationsAPI() {
    return new FormulationsAPI(this.api, API_VERSION);
  }

  getIngredientTemplateAPI() {
    return new IngredientTemplateAPI(this.api, API_VERSION);
  }

  getIngredientsAPI() {
    return new IngredientsAPI(this.api, API_VERSION);
  }

  getIngredientDatasetAPI() {
    return new IngredientDatasetAPI(this.api, API_VERSION);
  }

  getProcessTemplateAPI() {
    return new ProcessTemplateAPI(this.api, API_VERSION)
  }

  getProcessAPI() {
    return new ProcessAPI(this.api, API_VERSION);
  }

  getProcessDatasetAPI() {
    return new ProcessDatasetAPI(this.api, API_VERSION);
  }

  getProductDescriptionTemplateAPI() {
    return new ProductDescriptionTemplateAPI(this.api, API_VERSION);
  }

  getCrossContaminationPointAPI() {
    return new CrossContaminationPointsAPI(this.api, API_VERSION);
  }

  getCrossContaminationPointTemplateAPI() {
    return new CrossContaminationPointTemplateAPI(this.api, API_VERSION);
  }

  getHazardTemplateAPI() {
    return new HazardTemplateAPI(this.api, API_VERSION);
  }

  getHazardAPI() {
    return new HazardAPI(this.api, API_VERSION);
  }

  getHazardDatasetAPI() {
    return new HazardDatasetAPI(this.api, API_VERSION);
  }

  getCcpTemplateAPI() {
    return new CCPTemplateAPI(this.api, API_VERSION);
  }

  getCcpAPI() {
    return new CcpAPI(this.api, API_VERSION);
  }

  getCcpDeterminationWorksheetAPI() {
    return new CcpDeterminationWorksheetAPI(this.api, API_VERSION);
  }

  getProductDescriptionAPI() {
    return new ProductDescriptionAPI(this.api, API_VERSION);
  }

  getTrafficFlowDiagramAPI() {
    return new TrafficFlowDiagramAPI(this.api, API_VERSION);
  }

  getTrafficFlowDiagramTemplateAPI() {
    return new TrafficFlowDiagramTemplateAPI(this.api, API_VERSION);
  }

  getProcessFlowDiagramAPI() {
    return new ProcessFlowDiagramAPI(this.api, API_VERSION);
  }

  getProcessFlowDiagramTemplateAPI() {
    return new ProcessFlowDiagramTemplateAPI(this.api, API_VERSION);
  }

  getPolicyAPI() {
    return new PolicyAPI(this.api, API_VERSION);
  }

  //getTimerAPI() {
  //  return new TimerAPI(this.api, API_VERSION);
  //}

  getPackagingAPI() {
    return new PackagingAPI(this.api, API_VERSION);
  }

  getSignatureAPI() {
    return new SignatureAPI(this.api, API_VERSION);
  }

  getCameraImageAPI() {
    return new CameraImageAPI(this.api, API_VERSION);
  }

  getFormAPI() {
    return new FormAPI(this.api, API_VERSION);
  }

  getFacilityAPI() {
    return new FacilityAPI(this.api, API_VERSION);
  }

  getAdminToolAPI() {
    return new AdminToolAPI(this.api, API_VERSION);
  }

  getDeviationAPI() {
    return new DeviationAPI(this.api, API_VERSION);
  }

  getDeviationImagesAPI() {
    return new DeviationImagesAPI(this.api, API_VERSION);
  }

  getTaskAPI() {
    return new TaskAPI(this.api, API_VERSION);
  }

  getEventAPI() {
    return new EventAPI(this.api, API_VERSION);
  }

  getLogsAPI() {
    return new LogsAPI(this.api, API_VERSION);
  }

  getMonitoringAPI() {
    return new MonitoringAPI(this.api, API_VERSION);
  }

  getOnboardingAPI() {
    return new OnboardingAPI(this.api, API_VERSION);
  }

  getCustomFieldsAPI() {
    return new CustomFieldsAPI(this.api, API_VERSION);
  }
}

export default API;
